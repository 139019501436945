.footer a {
  text-decoration: none;
  color: white;
}

.footer,
.rest {
  display: flex;
}

.footer .social svg {
  fill: white;
}

.footer {
  width: 100%;
  align-content: space-between;
  align-items: center;
  height: 100%;
}



.social {
  margin-left: var(--spacing-64);
}

.rest svg {
  height: var(--spacing-16);
  vertical-align: text-bottom;

}

.brand > svg {
  height: var(--spacing-32);
}

.help > span + svg {
  margin-left: var(--spacing-8);
}


.help span {
  vertical-align: middle;
}

.social > a + a {
  margin-left: var(--spacing-16);
}
