.disputeSummary .inner {
  padding: var(--spacing-32);
}

.disputeSummary .description {
  margin-bottom: var(--spacing-32);
}

.disputeSummary .description hr {
  background-color: unset;
}


.disputeSummary .footer {
  text-align: center;
  background-color: var(--purple-light);
  padding: var(--spacing-16);
  margin: 0;
  border-bottom-left-radius: var(--border-radius-l);
  border-bottom-right-radius: var(--border-radius-l);
}

.disputeSummary .footer svg {
  margin-right: var(--spacing-8);
}

.disputeSummary iframe {
  width: 1px;
  min-width: 100%;
  margin-bottom: var(--spacing-32);
}
