.create {
  position: relative;
}

.create .markdown {
  word-wrap: break-word;
}

.create .fillUpTheForm {
  font-size: var(--font-size-s);
}

.create .h1 {
  font-size: var(--font-size-l);
  font-weight: var(--bold);
}

.create hr {
  width: 100%;
}

.create .scales {
  height: 16px;
  width: auto;
  margin-right: var(--spacing-4);
  vertical-align: text-bottom;
}

.create .dropdownToggle::after {
  position: absolute;
  right: var(--spacing-16);
  top: calc(50% - var(--spacing-4));
  color: var(--stroke);

}

.create .dropdownToggle {
  padding: 0.375rem 0.75rem;
  text-align: left;
}

.create .dropdownToggle a {
  background-color: red;
}

.create .dropdownToggle > span {
  vertical-align: middle;
}


.create .scales > path {
  fill: var(--purple-primary);
}

.create .arbitrationFeeGroup > div {
  box-shadow: var(--box-shadow);
}

.create .arbitrationFeeGroupPrepend {
  border: none;
}
.create .arbitrationFeeGroupPrepend > svg {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: var(--spacing-8);
}

.create .arbitrationFee {
  border: none;
  background-color: white;
  font-weight: var(--bold);
  color: var(--text-primary);
}

.create :global(#description) {
  margin-bottom: var(--spacing-4);
}

.create :global(markdown-toolbar) {
  color: var(--blue-primary);
}

.create :global(markdown-toolbar) > * > svg {
  margin-left: var(--spacing-16);
}

.create .buttonAlias {
  background-color: var(--blue-primary);
  font-size: 40px;
  border: none;
  border-radius: 50%;
}

.create :global(.cssCircle) {
  border-radius: 999px;
  display: inline-block;

  width: var(--spacing-32);
  height: var(--spacing-32);
  padding: 0px !important;

  color: white;
  background: var(--blue-primary);
  border: none;
  text-align: center;
  transition: background 0.2s linear;
  transition: color 0.2s linear;

  font-size: 26px;
}

.create :global(.cssCircle):hover {
  background: #3f69a0;
  cursor: pointer;
}


.create :global(.minusSign) > span {
  vertical-align: super;
  line-height: 27px;
}

.create :global(.plusSign) {
  line-height: 0;
  margin-right: var(--spacing-8);
}

.create :global(.minusSign):hover,
.create :global(.plusSign):hover {
  color: white;
}

.create .dropzoneRow {
  margin-top: var(--spacing-32);
}

.create .dropzone {
  background-color: var(--blue-accent);
  border: 2px dashed #009aff;
  text-align: center;
  margin-bottom: var(--spacing-8);
}

.create .dropzoneInfo > svg {
  margin-right: var(--spacing-8);
}

.create .submit {
  float: right;
  margin-right: var(--spacing-16);
}

/* enable absolute positioning */
:global(.inner-addon) {
  position: relative;
}

/* style icon */
:global(.inner-addon) :global(.glyphicon) {
  position: absolute;
  pointer-events: none;
}

/* align icon */
:global(.left-addon) :global(.glyphicon) {
  left: 14px;
  top: 42px;
}
:global(.right-addon) :global(.glyphicon) {
  right: 0px;
}

/* add padding  */
:global(.left-addon) input {
  padding-left: 40px;
}
:global(.right-addon) input {
  padding-right: 30px;
}
