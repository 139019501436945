.card {
  padding: var(--spacing-16) !important;
}

.filter :global(.dropdown-toggle.btn.btn-primary) {
  font-weight: var(--thin);
}

.filter :global(.dropdown-toggle.btn.btn-primary)::after {
  margin-left: var(--spacing-32);
  color: var(--stroke);
}

.filter :global(.dropdown-toggle.btn.btn-primary)::before {
  content: "●";
  vertical-align: text-bottom;
  margin-right: var(--spacing-8);
}

.filter:global(.evidence) :global(.dropdown-toggle.btn.btn-primary)::before {
  color: var(--green);
}

.filter :global(.dropdown-item.evidence)::before {
  color: var(--green);
}

.filter:global(.commit) :global(.dropdown-toggle.btn.btn-primary)::before {
  color: var(--blue-primary);
}

.filter :global(.dropdown-item.commit)::before {
  color: var(--blue-primary);
}

.filter:global(.vote) :global(.dropdown-toggle.btn.btn-primary)::before {
  color: var(--blue-primary);
}

.filter :global(.dropdown-item.vote)::before {
  color: var(--blue-primary);
}

.filter:global(.appeal) :global(.dropdown-toggle.btn.btn-primary)::before {
  color: var(--red);
}

.filter :global(.dropdown-item.appeal)::before {
  color: var(--red);
}

.filter :global(.dropdown-item)::before {
  content: "●";
  vertical-align: text-bottom;
  margin-right: var(--spacing-8);
}

.dropdownContainer {
  padding: var(--spacing-32);
}
