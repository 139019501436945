.dropzone {
  margin-top: 0.2rem;
  background-color: var(--blue-accent);
  border: 2px dashed #009aff;
  text-align: center;
  margin-bottom: var(--spacing-16);
}

.dropzone p {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0;
  padding: 1rem;
}

.dropzoneInfo > svg,
.dropzoneError > svg {
  margin-right: var(--spacing-8);
}

.dropzoneError {
  color: var(--red);
}

.uploadContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-8);
  margin: var(--spacing-16) 0;
}

.uploadContent p {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-s);
  color: var(--blue-primary);
}
