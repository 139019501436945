.summary .description {
  margin-bottom: var(--spacing-32);
}

.summary .buttons {
  text-align: right;
}



.summary .spanWithSvgInside > svg {
  margin-right: var(--spacing-8);
  vertical-align: text-bottom;
}

.summary .primaryDocument > svg {
  margin-right: var(--spacing-8);
  height: var(--spacing-16);
}

/* .summary .buttons {
  margin-top: -var(--spacing-16);
}

.summary .buttons > div + div {
  margin-top: var(--spacing-16);
} */

.summary .footer > div:first-child {
  margin: auto;
}
